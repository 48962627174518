/*HOME*/
.home{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home-bg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.home-bg {
  z-index: -1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 5vw;
}

/*ABOUT*/
.about p, .why p {
  padding-top: 5px;
}

/*Service*/


/*Project*/
.project h2 {
  text-align: center;
  padding: 46px 30px;
}

/*approach*/

.app {
  overflow-x: hidden;
  width: 100%;
}

/*project*/
.project img{
  width: 100%;
  object-fit: cover;
}

/*Contact*/
.contact{
  margin-top: 200px;
}

/* Menu Toggle */
.menu-toggle {
  position: fixed;
  top: 2rem;
  right: 5vw;
  border: none;
  background-color: transparent;
  opacity: .5;
  font-size: 1.625rem;
  cursor: pointer;
  z-index: 9999;
transition: .3s;
}

.menu .menu-toggle{
  opacity: 1;
}

/* Navigation */
.menu {
  position: fixed;
  inset: 0;
  background-color: #999999;
  transform: translateY(-100vh);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

nav{
  display: flex;
  flex-direction: column;
}

/* Navigation Links */
nav a {
  color: black;
  text-decoration: none;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  padding: 5px 0;
  text-transform: uppercase;
  font-family: "Atkinson Hyperlegible", sans-serif;
}

nav a:hover {
  color: white;
  transition: transform 0.6s ease-in-out;
}

/* Navigation Link Animation */
nav a::before {
  content: "";
  position: absolute;
  top: 50%;
  inset-inline: -0.5rem;
  height: 6px;
  background-color: currentColor;
  opacity: 0;
  transform: scaleX(0.6);
  transition: all 0.2s ease-out;
}

nav a:hover::before {
  opacity: 1;
  transform: scaleX(1);
}

/* Class for hiding the menu */
.hidden {
  display: none;
  opacity: 0;
}