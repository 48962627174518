@import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import "../font/1797.css";
*{
  box-sizing: border-box;
  font-family: "Atkinson Hyperlegible", sans-serif;
  margin: 0;
  padding: 0;
  color: #3b3b3b;
  font-kerning: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}

.border-top{
border-top: 1px solid;
}

.border-bottom{
  border-bottom: lightgray 1px solid;
}


section{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 100px 0;

}

.number{
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 22px;
}

.logo{
  position: fixed;
  top: 2rem;
  left: 5vw;
  color: #999999;
  font-size: 1.625rem;
  z-index: 9999;
  font-family: "Atkinson Hyperlegible", sans-serif;
  transition: .3s;
}

.menu .logo{
  color: black;
}

p{
  font-size: 16px;
  line-height: 19px;
  font-family: "Atkinson Hyperlegible", sans-serif;
  color: #626262;
}

h1{
  font-size: 5vw;
  color: white;
  font-family: "Atkinson Hyperlegible", sans-serif;
}

h2{
  font-size: 60px;
  letter-spacing:-1px;
  line-height: 80px;

}

.slogan{
  text-transform: uppercase;
  font-size: 90px;
  letter-spacing:-1px;
  line-height: 80px;
}

h3{
  color: #494949;
  font-size: 45px;
}

h4{
  color: #565656;
}

span{
  font-size: inherit;
  font: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  color: inherit;
}
.flex-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.flex-wrapper{
  display: flex;
}

@media only screen and (max-width: 900px) {
  body {
    padding: 0 30px;
    overflow-x: hidden;
  }
  h2, .slogan{
    font-size: 50px;
    line-height: 50px;
  }

  h3{
    font-size: 20px;
  }
}

@media only screen and (max-width: 400px) {

  h2, .slogan{
    font-size: 35px;
  }

  .logo{
    font-size: 17px;
  }
}

