@font-face {
    font-family: '1797 NUMBERS';
    src: url('1797-NUMBERS.woff2') format('woff2'),
        url('1797-NUMBERS.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '1797 NUMBERS HALF';
    src: url('1797-NUMBERSHALF.woff2') format('woff2'),
        url('1797-NUMBERSHALF.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '1797';
    src: url('1797-ITALIC.woff2') format('woff2'),
        url('1797-ITALIC.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: '1797 COMPRESSED';
    src: url('1797-COMPRESSED.woff2') format('woff2'),
        url('1797-COMPRESSED.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '1797 POSTER';
    src: url('1797-POSTER.woff2') format('woff2'),
        url('1797-POSTER.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '1797';
    src: url('1797-MEDIUM.woff2') format('woff2'),
        url('1797-MEDIUM.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '1797 SIGNAGE';
    src: url('1797-SIGNAGEItalic.woff2') format('woff2'),
        url('1797-SIGNAGEItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: '1797 SIGNAGE';
    src: url('1797-SIGNAGE.woff2') format('woff2'),
        url('1797-SIGNAGE.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

